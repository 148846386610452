.hyperlink-item{
    margin-top: 15px;
    font-size: 20px;
    text-align: center;
}

.hyperlink-item-mobile{
    margin-top: 15px;
    font-size: 18px;
    text-align: center;
}
.hero-container {
    position: relative;
    width: 100%;
    box-shadow: 0px 0px 3px 2px #bbb;
    display: flex; 
    align-items: center; 
    justify-content: center;
    flex-direction: column;
}

.hero-background{
    min-height: 620px;
}

.hero-background-lg{
    min-height: 800px;
}

.hero-background-tablet{
    min-height: 600px;
}

.hero-background-mobile{
    min-height: 500px;
}

.hero-background-mobile-md{
    min-height: 475px;
}

.hero-background-mini{
    min-height: 500px;
}

.hero-background-lg-mini{
    min-height: 600px;
}

.hero-background-tablet-mini{
    min-height: 350px;
}

.hero-background-mobile-mini{
    min-height: 350px;
}

.hero-background-mobile-md-mini{
    min-height: 350px;
}

.hero-logo{
    height: 200px;
}

.hero-logo-md{
    height: 150px;
}

.hero-logo-sm{
    height: 85px;
}

.hero-title{
    margin: 20px;
}

.leagues-select
{
    width: 200px;
    font-size: 18px;
    margin: 5px;
}

.league-table-container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
    overflow-x:auto;
}

.table-container{
    background: #fff;
}

.table-sub-row{
    padding: 20px;
    text-align: left;
}

table {
    border-spacing: 0;
    margin: 10px auto;
    border: 1px solid #ccc;
    color: #4b4b4b;
    border-collapse:separate;
    border-radius:6px;
    -moz-border-radius:6px;
    box-shadow: 0px 0px 3px #ccc;
    display: block;
    overflow-y: hidden;
    overflow-x: auto;

    tr {
        border-color: #ccc;
        width: 100%;
      :last-child {
        td {
          border-bottom: 0;
          border-right: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      position: relative;
      justify-content: center;
      align-items: center;
      display: 'flex';
    }

    th {
        background-color: #6b6b6b;
        border-top: none;
        color: #fff;
    }

    td:first-child, th:first-child {
        border-left: none;
    }

    th:first-child {
        border-top-left-radius: 5px;
    }
    th:last-child {
        border-top-right-radius: 5px;
    }

    .thead {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .tbody {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
  }

.table-sort-icon{
    color:#fff;
}

.table-input{
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 5px;
    margin: 0;
    min-width: 100px;
    max-width: 150px;
}

.table-pagination-item{
    margin-top:10px;
    margin-bottom:10px;
}

.table-layout-container{
    width: 100%;
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.table-search-container{
    margin: 10px;
    width: 100%;
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.table-global-search{
    margin-left: auto;
    margin-right: 10px;
}

.table-header-width{
    width: 1000px;
}
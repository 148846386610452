.btn-button{
    padding: 7px 10px;
    font-size: 18px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    margin: 5px;
}

.btn-button-mobile{
    padding: 7px 10px;
    font-size: 12px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    margin: 5px;
}

.footer-outer{
    padding: 10px;
    min-height: 75px;
    box-shadow: 0px 0px 3px 2px #bbb;
    display: flex; 
    align-items: center; 
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.news-row-outer{
    position: relative;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.news-row-container{
    position: relative;
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    text-align: center;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    padding-bottom: 20px;
}

.news-row-container-mobile{
    flex-wrap: wrap-reverse;
}

.news-row-item{
    flex-direction: column;
    max-width: 481px;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 20px;
}

.news-banner{
    display: flex;
    flex-direction: row;
    text-align: center;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
}

.news-card{
    padding: 20px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: #ccc 0px 5px 0px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.news-banner-container{
    position: relative;
}

.news-left-banner-button{
    position: absolute;
    top: 50%;
    color: #6acc48;
    font-size: xx-large;
    font-weight: bold;
    z-index: 81;
    left: 10px;
    
}

.news-right-banner-button{
    position: absolute;
    top: 50%;
    color: #6acc48;
    font-size: xx-large;
    font-weight: bold;
    z-index: 81;
    right: 10px;
}

.news-up-banner-button{
    color: #6acc48;
    font-size: xx-large;
    font-weight: bold;
    z-index: 81;
}

.news-down-banner-button{
    color: #6acc48;
    font-size: xx-large;
    font-weight: bold;
    z-index: 81;
}

.news-container-card-title{
    text-align: center;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 2px #ccc;
    margin-bottom: 35px;
}

.news-container-card{
    text-align: center;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 2px #ccc;
    width: 95%;
    margin-bottom: 35px;
}
.main-title-container{
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    color: #fff;
    background-color: #6acc48;
    padding: 7px 10px;
    width: 100%;
}

.main-title-container-mobile{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    border-radius: 10px;
    color: #fff;
    background-color: #6acc48;
    padding: 7px 10px;
}
.header-container {
    position: fixed;
    top: 0;
    width:100%;
    overflow: hidden;
    margin:0;
    padding:0;
    box-shadow: 0px 0px 2px 1px #aaa;
    z-index: 5;
}

.header-outer{
    padding: 0;
    position: relative;
    width: 100%;
    display: flex; 
    align-items: center; 
    justify-content: center;
}

.header-row {
    margin: 10px;
    width: 100%;
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-direction: row;
    max-width: 730px;
}

.header-row-mobile {
    margin: 10px;
    width: 100%;
    display: flex; 
    flex-direction: row;
}

.header-col-left {
    margin-right: auto;
    overflow: hidden;
}

.header-col {
    display: flex; 
    justify-content: center;
    overflow: hidden;

    margin-left: 20px;
    margin-right: 20px;
}

.header-col-center {
    display: flex; 
    justify-content: center;
    overflow: hidden;
    margin: 0 auto;
}

.header-col-right {
    margin-left: auto;
    overflow: hidden;
}

.header-logo{
    height: 45px;
}

.header-link{
    font-size: 16px;
    color: #4b4b4b;
}

.header-link:hover{
    font-weight:bold;
}

.header-login-icon{
    font-size: 26px;
    color: #4b4b4b;
    
}

.header-login-icon:hover{
    color: #6acc48;
}

.header-button-stealth{
    background-color: rgba(255,255,255,0);
    border: 0px;
    padding: 0px;
}

.header-button-stealth:active { 
    outline: none;
    border: none;
}

.header-button-stealth:focus {
    outline:0;
}

.closeBtn {
    background-color: rgba(255,255,255,0);
    border: 0px;
    position: absolute;
    top: 25px;
    left: 25px;
    font-size: 36px;
    margin-right: 50px;
    color:#4b4b4b
}
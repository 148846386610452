.sub-title-container{
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    border-radius: 10px;
    color: #fff;
    background-color: #4b4b4b;
    padding: 7px 10px;
    width: 100%;
    margin-top: 20px;
}

.sub-title-container-mobile{
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    border-radius: 10px;
    color: #fff;
    background-color: #4b4b4b;
    padding: 7px 10px;
    width: 100%;
    margin-top: 20px;
}

.warning-container{
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    border-radius: 10px;
    color: #fff;
    background-color: #ff0000;
    padding: 7px 10px;
    width: 100%;
    margin-top: 20px;
}

.warning-container-mobile{
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    border-radius: 10px;
    color: #fff;
    background-color: #ff0000;
    padding: 7px 10px;
    width: 100%;
    margin-top: 20px;
}
.contact-form-error{
    color:red;
    font-weight:bold;
    background: #fff;
    margin: 1px;
    padding: 2px;
    border-radius: 5px;
}

.contact-form{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-form-card{
    width:100%;
    max-width: 700px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: #4b4b4b;
    box-shadow: 0px 2px 2px #ccc;
}

.contact-form-background{
    min-height: 620px;
}

.contact-form-background-lg{
    min-height: 800px;
}

.contact-form-background-tablet{
    min-height: 600px;
}

.contact-form-background-mobile{
    min-height: 500px;
}

.contact-form-background-mobile-md{
    min-height: 475px;
}

.contact-form-title{
    font-size: 40px;
    font-weight: bold;
    margin: 20px;
}

.contact-form-inputs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.contact-form-input{
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 7px;
    font-size: 18px;
    margin: 5px;
    width: 100%;
}

.contact-form-recap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.contact-form-buttons{
    padding:10px;
}


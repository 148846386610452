.text-item{
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
}

.text-item-mobile{
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
}
.drop-bar-down {
    height: 90%;
    padding: 0px 10px;
    transition: height 0.5s cubic-bezier(0.5,0.37,0.58,1);
}

.drop-bar-closed {
    height: 0;
    padding: 0;
    transition: height 0.5s cubic-bezier(0.42,0,0.58,1);
}

.drop-bar-row {
    display: flex; 
    align-items: center; 
    justify-content: center;
    height: 60px;
    transition: height 0.8s cubic-bezier(0.42,0,0.58,1);
}
.drop-bar-row-closed {
    display: flex; 
    align-items: center; 
    justify-content: center;
    height: 0px;
    transition: height 0.8s cubic-bezier(0.42,0,0.58,1);
}

.drop-bar-break {
    height: 1px;
    width: 100%;
    border: 1px solid #ccc;
}